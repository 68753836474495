@import 'shared/styles/common.scss';

.root {
	position: fixed;
	z-index: 100000;
	
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	$body-bg: $pinkBg; // darken($brand-primary, 25%);

	background: $body-bg;

	opacity: 1;
	transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);

	// RubberIcon - if we even include it...
	svg {
		position: absolute;
		z-index: 1002;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(2);
		opacity: 0.35;
		transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
	}

	&::before {
		content: ' ';
		display: block;

		position: absolute;

		z-index: 1001;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);

		width: 8rem;
		height: 8rem;
		background: lighten($body-bg, 20%);
		border-radius: 100%;

		box-shadow: 0 0 0 0 $body-bg;
		animation: pulse 1.5s infinite;

		// Original pulsing animation and styles based on
		// https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
		@keyframes pulse {
			0% {
				transform: translate(-50%, -50%) scale(0.75);
				box-shadow: 0 0 0 0 transparentize($body-bg, .3);
			}
		
			70% {
				transform: translate(-50%, -50%) scale(1);
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}
		
			100% {
				transform: translate(-50%, -50%) scale(0.75);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}
		}

		@keyframes grow {
			0% {
				transform: translate(-50%, -50%) scale(0.75);
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}
		
			15% {
				transform: translate(-50%, -50%) scale(3);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}

			40% {
				transform: translate(-50%, -50%) scale(4);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}
		
			100% {
				transform: translate(-50%, -50%) scale(10);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}
		}
	}

	&.close {
		opacity: 0;
		pointer-events: none;

		&::before {
			animation: grow 2s;
		}

		svg {
			transform: translate(-50%, -50%) scale(0);
		}
	}

	&.stop {
		display: none;

		&::before {
			display: none;
			animation: none;
		}
	}
}