@import './_include-media';

// Import font from google
// @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&display=swap');

$serif-font: 'EB+Garamond', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

// Basic for use elsewhere
$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

// $foreground-color: #000; //rgba(255,255,255,0.9);	

$foreground-color: rgba(255,255,255,0.9);	

// New colors from Aly's Email "Icon 1024" 9/30/21 1:38pm
// https://mycolor.space/?hex=%236A2A5B&sub=1
$pinkBg: rgba(106,42,91,1); // #6A2A5B, Deep Purple, Pantone 7650
$pink1: rgba(212,139,200,1); // #D48BC8, Med Purple, Pantone 514
$pink2: rgba(226,172,215,1); // #E2ACD7, Light purple, Pantone 515
$gray1: rgba(65,65,65,1);

$pinkShadow: transparentize(darken($pinkBg, 17%), .05);
$pinkShadow2: transparentize(darken($pink1, 17%), .05);

// For use with body.notched utilitiy calss
$notch-size: 1.75rem;

// Override default variables before the import of bootstrap
$body-bg: rgba(42,42,42,1); // darken(#743089, 25%);
$content-color: #fff;
$content-bg:    #000;

// $body-bg: #000;
// $content-color: #fff;
// $content-bg:    #000;


// $brand-primary: #743089;
$brand-primary: $pinkBg;
$brand-secondary: #000000;
$brand-third: #E1954E;

// RGB: 181, 71, 155
// HSB: 314, 61, 71 
// https://colors.dopely.top/color-mixer/B5479B-100
$highlight-color: #B5479B;  
// $highlight-color: $pink1;


$breakpoint-small: 600px;

// $font: Dimbo-Regular, sans-serif;

@mixin fb-btn-overrides() {
	background-color: #4C69BA;
	background-image: linear-gradient(#4C69BA, #3B55A0);
	/*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
	text-shadow: 0 -1px 0 #354C8C;

	// padding-left: 2.5rem;

	// &::before {
	// 	content: "";
	// 	box-sizing: border-box;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 2rem;
	// 	height: 100%;
	// }
	
	// &::before {
	// 	border-right: #364e92 1px solid;
	// 	background: url('/icon_facebook.png') no-repeat;
	// 	background-position: .5rem .6rem;
	// }
	&::hover,
	&::focus {
		background-color: #5B7BD5;
		background-image: linear-gradient(#5B7BD5, #4864B1);
	}
}

@mixin customIgOverlayDark() {
	// filter: sepia(.25) contrast(1.5) brightness(.9) hue-rotate(-15deg);
	
	// &::before {
	// 	background: radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(0, 0, 0, .65) 100%);
	// 	content: "";
	// 	mix-blend-mode: screen;
	// }

	// This is just a simple overlay to add a gradient effect
	// similar to https://www.instagram.com/p/B9-QFQjJDdo/
	&::after {
		display: block;
		position: fixed;
		pointer-events: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5000;

		// border: 3px solid red;

		// background: radial-gradient(circle closest-corner, rgba(78, 71, 71, .5) 0, rgba(0, 0, 0, .65) 100%);
		// content: "";
		mix-blend-mode: screen;

		// background: rgba(78,71,71,0.5);
		// I really liked the effect generated by the IG filter 
		// shown on https://www.instagram.com/p/B9-QFQjJDdo/, 
		// so I tried to recreate it with this gradient overlay
		background: radial-gradient(ellipse farthest-corner at 62.5% 33%,
				rgba(87,80,80,0.7), 
				rgba(78,71,71,0.4) 30%, 
				rgba(78,71,71,0.2) 70%, 
				transparent
			),
			radial-gradient(circle closest-side at 55% 45%,
				rgba(87,80,80,0.4), 
				rgba(78,71,71,0.4) 30%, 
				rgba(78,71,71,0.2) 70%, 
				transparent
			);
		opacity: 0.925;

		content: ' ';
	}
}


@mixin customIgOverlayLight() {
	 // This is just a simple overlay to add a gradient effect
	// similar to https://www.instagram.com/p/B9-QFQjJDdo/
	&::after {
		display: block;
		position: fixed;
		pointer-events: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5000;

		mix-blend-mode: screen;

		// background: rgba(242, 194, 255,0.5);
		// I really liked the effect generated by the IG filter 
		// shown on https://www.instagram.com/p/B9-QFQjJDdo/, 
		// so I tried to recreate it with this gradient overlay
		background: radial-gradient(ellipse farthest-corner at 62.5% 40%,
				rgba(242, 194, 255,0.8), 
				rgba(242, 194, 255,0.4) 30%, 
				rgba(242, 194, 255,0.1) 50%, 
				transparent
			),
			radial-gradient(circle closest-side at 65% 55%,
				rgba(242, 194, 255,0.4), 
				rgba(242, 194, 255,0.1) 50%, 
				transparent
			);
		opacity: 0.5;
		content: ' ';
	}
}

$my-cubic-transition: cubic-bezier(0.425, -0.600, 0.700, 1.520);
$default-transition-time: 1s / 6 !default;
@mixin transition-all($time: $default-transition-time, $customize_props: false) {
	//transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
	@if $customize_props {
		transition: all $time $my-cubic-transition, //cubic-bezier(.42, 0, .58, 1),
					font-size 0s,
					$customize_props;
	} @else {
		transition: all $time $my-cubic-transition, //..cubic-bezier(.42, 0, .58, 1),
					font-size 0s;
	}
}

//https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
@mixin noselect() {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome and Opera */
  }
  
  // From https://github.com/sass/sass/issues/183
  $z-index: 0;
  @function next-z-index() {
	  $z-index: $z-index + 1;
	  @return $z-index;
  }
  
  @mixin fullpage() {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  z-index: next-z-index();
	  overflow-y: auto;
  }
  
  @mixin main-card-root() {
	  @include fullpage();
	  z-index: next-z-index();
  
	  display:          flex;
	  justify-content:  center;
	  align-items:      center;
	  transform-origin: top center;
  
	  transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
  }
  
  @mixin force-word-break() {
	  // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
	  /* These are technically the same, but use both */
	  overflow-wrap: break-word;
	  word-wrap: break-word;
  
	  -ms-word-break: break-all;
	  /* This is the dangerous one in WebKit, as it breaks things wherever */
	  word-break: break-all;
	  /* Instead use this non-standard one: */
	  word-break: break-word;
  
	  /* Adds a hyphen where the word breaks, if supported (No Blink) */
	  -ms-hyphens: auto;
	  -moz-hyphens: auto;
	  -webkit-hyphens: auto;
	  hyphens: auto;
  };
  
  

  $default-transition-time: 1s / 4 !default;
@mixin transition-all($time: $default-transition-time, $customize_props: false) {
	//transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
	@if $customize_props {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
					font-size 0s,
					$customize_props;
	} @else {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //..cubic-bezier(.42, 0, .58, 1),
					font-size 0s;
	}
}

@mixin stylize-button($norm, $bg-tx: .65, $br-tx: .85, $dark-perc: 10%) {

	color: transparentize($content-color, .1);
	border-radius: 1rem;
	@include transition-all(.2s);

	// Note must match bootstrap specificity to override without !important
	&:not(:disabled):not(:global(.disabled)):active {
		// background: transparentize($content-bg, .55);
		transform: scale(.95);
	}

	background:   transparentize($norm, $bg-tx);
	border-color: transparentize($norm, $br-tx);

	&:hover, &:focus {
		box-shadow: 0 0 0 0.2rem transparentize($norm, $br-tx - .05);
	}

	&:not(:disabled):not(:global(.disabled)):active {
		$dark: darken($norm, $dark-perc);

		background:   transparentize($dark, $bg-tx);
		border-color: transparentize($dark, $br-tx);
		box-shadow: 0 0 0 0.2rem transparentize($dark, $br-tx - .05);
	}
}
