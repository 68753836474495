@tailwind base;
@tailwind components;
@tailwind utilities;

/* This "reverts" the changes '@tailwind base' does, since some parts of the app rely on the h* default styles */
h1, h2, h3, h4, h5, h6 {
	font-size: revert;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    /* Revert the focus changes */
	box-shadow: none;
}

body {
  /* margin: 0; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #282c34; */
  /* color: white; */
  /* min-height: 100vh;
  display: flex;
  flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* background: black !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
